import React from "react";
import useSWR from "swr";
import api_routes from "../util/api_routes";
import axios from "axios";

function useCloudCredentials() {

    const url = api_routes.cloudCredentials.endpoint

    async function fetchData(...args) {
        try {
            let response = await axios.get(args);
            return response.data
        } catch (e) {
            throw new Error(`Error fetching cloud credentials list due to: ${e.message}`)
        }
    }

    const {data, error, isLoading, isValidating, mutate} = useSWR(url,
        fetchData,
        {
            fallback: []
        }
    )

    async function refresh() {
        await mutate()
    }

    return {
        credentials: data,
        error,
        isLoading,
        isValidating,
        refresh
    }
}

useCloudCredentials.propTypes = {};

useCloudCredentials.defaultProps = {};


export default useCloudCredentials;