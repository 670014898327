import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PasswordField from '../common/PasswordField';
import FormControlInput from '../common/FormControlInput';

// noinspection FunctionNamingConventionJS
function AwsCloudConnectionCredentialsForm(props) {

    const [displayNameManualChange, setDisplayNameManualChange] = useState(props.credentials.displayName !== "");

    const handleTextChange = (event) => {
        const {name, value} = event.target;
        let displayNameUpdate = {};

        if (name === 'displayName' && value) {
            setDisplayNameManualChange(true);
        } else if (name === 'displayName' && !value) {
            setDisplayNameManualChange(false);
        } else if (!displayNameManualChange && name === 'accessKeyId') {
            displayNameUpdate = {displayName: `AWS - ${value}`};
        }
        props.handleChange({[event.target.name]: event.target.value, ...displayNameUpdate});
    }

    return (<>
        <FormControlInput value={props.credentials.accessKeyId}
                          name='accessKeyId'
                          label='AWS Access Key ID'
                          helperText='This AWS ID is used for this cloud connection.'
                          placeholder='Enter Key ID'
                          onChange={handleTextChange}
                          errorMessage={props.errors.accessKeyId}
                          autoComplete='off'
                          width='100'
        />
        <FormControlInput value={props.credentials.accessSecret}
                          name='accessSecret'
                          label='AWS Access Secret'
                          helperText='This AWS access secret is used for this cloud connection.'
                          placeholder={`${props.credentials.secretSet ? 'Hidden Value Is Set' : 'Enter secret for key ID'}`}
                          onChange={handleTextChange}
                          errorMessage={props.errors.accessSecret}
                          autoComplete='off'
                          width='100'
        />
        <FormControlInput value={props.credentials.displayName}
                          label='Credential Display Name'
                          name='displayName'
                          helperText='Name used to identify this cloud connection. By default the name is "AWS - {Access Key ID}".'
                          placeholder={'Enter unique name'}
                          onChange={handleTextChange}
                          autoComplete='off'
                          errorMessage={props.errors.displayName}
                          width='100'
        />
    </>);
}

AwsCloudConnectionCredentialsForm.propTypes = {
    credentials: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
};

AwsCloudConnectionCredentialsForm.defaultProps = {
    credentials: {},
    errors: {},
    handleChange: () => {
    },
};

export default AwsCloudConnectionCredentialsForm;
