import React, {useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import {Box} from '@mui/material';

const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const StyledCheckIcon = styled(CheckIcon)`
    margin-right: 4px;
    font-weight: 900;
`;

// noinspection FunctionNamingConventionJS
function SuccessMessageComponent({show, setShow, timeout, message, ...props}) {

    const {mt, mr, mb, ml} = props;

    useEffect(() => {
        // noinspection MagicNumberJS
        const timeId = setTimeout(() => {
            // After 4 seconds, set the show value to false
            setShow(false)
        }, timeout);

        return () => {
            clearTimeout(timeId)
        }
    }, [show, setShow, timeout]);

    let component = null;
    if (show) {
        component = (
            <StyledBox color='success.main' mt={mt} mr={mr} mb={mb} ml={ml}>
                <StyledCheckIcon fontSize='small'/> <span>{message}</span>
            </StyledBox>
        );
    }

    return component;
}

SuccessMessageComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    timeout: PropTypes.number,
    color: PropTypes.string,
    message: PropTypes.string,
    mt: PropTypes.number,
    mr: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
};

SuccessMessageComponent.defaultProps = {
    show: true,
    timeout: 3000,
    color: 'success.main',
    message: 'Saved',
};

export default SuccessMessageComponent;
