import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {drawerColor} from '../layout/ResponsiveDrawer';

// noinspection FunctionNamingConventionJS
function SearchField(props) {

    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        if (searchStr === '') {
            props.onSubmit(searchStr);
        }
    }, [searchStr]);

    const onSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(searchStr);
    }

    return (
        <Paper
            component='form'
            sx={{ p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: 200,
                borderRadius: '25px',
                backgroundColor: drawerColor,
                boxShadow: 'none',
            }}
            onSubmit={onSubmit}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={props.placeholder}
                value={searchStr}
                onChange={(event) => setSearchStr(event.target.value)}
            />
            <IconButton type="submit" size='small' aria-label="search">
                <SearchIcon fontSize='small' />
            </IconButton>
        </Paper>
    );
}

SearchField.propTypes = {
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func,
};
SearchField.defaultProps = {
    placeholder: 'Search...',
    onSubmit: (searchStr) => { console.log(searchStr) },
};

export default SearchField;
