import React, {useState} from 'react';
import toast from 'toasted-notes';
import {useHistory, useLocation} from 'react-router-dom';
import {Box, Typography, useTheme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import routes from '../../../util/routes';
import CustomAlert from '../../common/CustomAlert';
import {isEmpty} from '../../../util/helpers';
import {AWS, AZURE, GCP} from '../../../util/cloud_providers';
import AwsCloudConnectionCredentialsForm from "../../cloudconnectioncredentials/AwsCloudConnectionCredentialsForm";
import AzureCloudConnectionCredentialsForm from "../../cloudconnectioncredentials/AzureCloudConnectionCredentialsForm";
import GcpCloudConnectionCredentialsForm from "../../cloudconnectioncredentials/GcpCloudConnectionCredentialsForm";
import CircularProgressButton from "../../common/CircularProgressButton";
import axios from "axios";
import api_routes from "../../../util/api_routes";
import {Prompt} from "react-router";
import {convertErrorsToObject} from "../../../util/errorHandler";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

const initialState = {
    accessKeyId: '',
    accessSecret: '',
    jwtToken: '',
    jsonKeyFilename: '',
    connectionString: '',
    displayName: '',
};

// noinspection FunctionNamingConventionJS
function CloudCredentialCreateComponent() {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const location = useLocation();

    const provider = (!isEmpty(new URLSearchParams(location.search).get('provider')))
        ? new URLSearchParams(location.search).get('provider')
        : 'aws';

    function providerTitle() {
        switch (provider) {
            case AWS:
                return 'AWS';

            case AZURE:
                return 'Azure';

            case GCP:
                return 'Google';
            default: {
                console.error("Unsupported Cloud Provider")
                return 'Unsupported'
            }
        }
    }

    const [isBlocking, setIsBlocking] = useState(false);
    const [saving, setSaving] = useState(false);

    const [credential, setCredential] = useState(initialState);
    const [errors, setErrors] = useState({});

    const handleCredentialsChange = (data) => {
        setCredential(prevState => {
            let newState = {...prevState, ...data};

            Object.keys(data).forEach(prop => {
                if ((isEmpty(initialState[prop]) && isEmpty(data[prop])) || (data[prop] === initialState[prop])) {
                    setIsBlocking(false);
                } else {
                    setIsBlocking(true);
                }
            })

            return newState;
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setSaving(true);
        try {
            let payload = {...credential, 'cloudProvider': provider, "displayName": credential.displayName.trim()};
            await axios.post(api_routes.cloudCredentials.endpoint, payload);
            toast.notify(({onClose}) =>
                <CustomAlert message={`Created cloud credential ${credential.displayName}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            setErrors(convertErrorsToObject(error.response));
        } finally {
            setSaving(false)
        }
    }

    function handleCancel() {
        history.push(routes.settings.path);
    }

    // noinspection MagicNumberJS
    return (
        <div className={classes.root}>
            <form
                onSubmit={handleSubmit}
                noValidate
            >
                <Typography variant={'h5'}>{routes.createCloudCredentials.pageTitle(providerTitle())}</Typography>
                {provider === AWS &&
                    <AwsCloudConnectionCredentialsForm credentials={credential}
                                                       handleChange={handleCredentialsChange}
                                                       errors={errors}/>
                }
                {provider === AZURE &&
                    <AzureCloudConnectionCredentialsForm credentials={credential}
                                                         handleChange={handleCredentialsChange}
                                                         errors={errors}/>
                }
                {provider === GCP &&
                    <GcpCloudConnectionCredentialsForm credentials={credential}
                                                       handleChange={handleCredentialsChange}
                                                       errors={errors}/>
                }
                <Box display='flex'>
                    <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                            label='Save' mt={2}
                                            inProgress={saving}
                                            fullWidth={false}
                    />
                    <CircularProgressButton label='Cancel' theme={theme} size='small' mt={2}
                                            onClick={handleCancel} variant='text'
                    />
                    <Prompt when={!saving && isBlocking}
                            message={'Are you sure you would like to cancel? Your changes will not be saved.'}/>
                </Box>

            </form>
        </div>
    );
}

export default CloudCredentialCreateComponent;
