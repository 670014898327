import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid } from '@mui/material';
import useFilesystem from '../../api/useFilesystem';
import PageLoader from '../common/PageLoader';
import {NoOverflowTypography} from '../common/styled';
import HelpSystemContext from '../../context/HelpSystemContext';
import FolderFormWrapper from './FolderFormWrapper';
import useQuery from '../../hook/useQuery';
import api_routes from '../../util/api_routes';
import {CustomWidthTooltip} from './FilesystemTableComponent';

function FsFolderCreateComponent() {

    let query = useQuery();
    const [folderName, setFolderName] = useState('');
    const [userPermissions, setUserPermissions] = useState();
    const [errors, setErrors] = useState({});
    const {data, isLoading} = useFilesystem({params: {path: query.get('path'), metadataOnly: true}});

    let parentFolder;
    if (data && data.length > 0) {
        parentFolder = data[0]
    }

    useEffect(() => {
        setUserPermissions(parentFolder?.userPermissions?.map(perm => {
            //Set all permissions to inheritable, since they are from the parent folder
            perm.permissions.inheritable = true;
            //Set all "isHomeFolder" booleans to false, since this is a new folder
            perm.isHomeFolder = false;
            return perm;
        }))
    }, [parentFolder]);

    const createFolder = async function createFolder({cloudConnectionId}) {
        let filesystem_api = `${api_routes.filesystem.endpoint}/${query.get('path')}`;
        return await axios.post(filesystem_api,
            { folderName, connectionId: cloudConnectionId, permissions: userPermissions },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
    };

    return (<Box p={3}>
        <CustomWidthTooltip title={`Add Folder to '/${query.get('path')}'`} sx={{maxWidth: '500'}}>
            <NoOverflowTypography variant='h5'>{`Add Folder to '/${query.get('path')}'`}</NoOverflowTypography>
        </CustomWidthTooltip>
        <HelpSystemContext.Consumer>
            {open =>
                <Grid container>
                    <Grid container item justifyContent='center'>
                        {isLoading && <PageLoader/>}
                    </Grid>
                    <Grid item xs={12} md={open ? 12 : 10} xl={open ? 10 : 8}>
                        {!isLoading &&
                            <FolderFormWrapper folderName={folderName}
                                               setFolderName={setFolderName}
                                               saveFolder={createFolder}
                                               userPermissions={userPermissions}
                                               setUserPermissions={setUserPermissions}
                                               folder={parentFolder}
                                               isCreatingFolder={true}
                                               errors={errors}
                                               setErrors={setErrors}
                                               path={query.get('path')}
                            />
                        }
                    </Grid>
                </Grid>
            }
        </HelpSystemContext.Consumer>
    </Box>);
}

FsFolderCreateComponent.propTypes = {};

export default FsFolderCreateComponent;
