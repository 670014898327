import React, {useState} from 'react';
import {Box, Card, CardContent, Link, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsCompCloudCredentialsList from './cloudcredential/SettingsCompCloudCredentialsList';
import routes from '../../util/routes';
import SettingsCompIdpList from './identityprovider/SettingsCompIdpList';
import ThemeCustomizationComponent from '../themecustomization/ThemeCustomizationComponent';
import SettingsComponentBackupRecovery from "./backuprecovery/SettingsComponentBackupRecovery";

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
    subheader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    card: {
        marginBottom: theme.spacing(4),
    }
}));

// noinspection FunctionNamingConventionJS
export default function SettingsComponent() {
    const classes = useStyles();

    const [triggerRefresh, setRefresh] = useState(false);
    const refresh = function refreshListOfUsers() {
        setRefresh(!triggerRefresh);
    };

    // noinspection MagicNumberJS
    return (
        <Box p={3}>
            <Typography variant='h5'>{routes.settings.pageTitle}</Typography>
            <Typography className={classes.subheader} variant='body1'>Use these settings to manage
                some global configuration options for StorageLink. These settings are a subset of
                available configurations. If you would like to set advanced configuration options, visit
                the <Link href={routes.kb.path} target='_blank' rel='noopener noreferrer'>
                    {routes.kb.menuTitle}</Link> or contact us at <Link href='mailto:support@thorntech.com'>
                    support@thorntech.com</Link>.
            </Typography>

            <Card className={classes.card} elevation={16}>
                <CardContent>
                    <SettingsCompCloudCredentialsList triggerRefresh={triggerRefresh} refresh={refresh}/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={16}>
                <CardContent>
                    <SettingsCompIdpList/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={16}>
                <CardContent>
                    <ThemeCustomizationComponent/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={16}>
                <CardContent>
                    <SettingsComponentBackupRecovery/>
                </CardContent>
            </Card>
        </Box>
    )
}

SettingsComponent.propTypes = {};
