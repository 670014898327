import React, {useCallback, useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import jwt_decode from 'jwt-decode';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Box, Grid, useTheme} from '@mui/material';
import useQuery from '../../hook/useQuery';
import CustomAlert from '../../component/common/CustomAlert';
import PasswordField from '../../component/common/PasswordField';
import UpdatePassword from '../../component/auth/UpdatePassword';
import FormControlInput from '../../component/common/FormControlInput';
import CircularProgressButton from '../../component/common/CircularProgressButton';
import {StyledLogo, StyledFormLogo, StyledFormWrapper} from '../../component/auth/Login.js';
import routes from '../../util/routes';
import {isEmpty} from '../../util/helpers';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import {authenticationService} from './authenticationService';
import {setAuthenticated, setCurrentUser, setSystemInformation} from '../../action';

const StyledWarningMessage = styled.div`
    margin: 24px 0px 16px 0px;
    font-style: italic;
    background-color: #fff0dc;
    padding: 8px;
    font-size: 0.875rem;
`;

export default function LinkExternalAccount() {
    let query = useQuery();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [loginForm, setLoginForm] = useState({
        username: query.get('name'),
        password: '',
    });
    const [performPasswordReset, setPerformPasswordReset] = useState(false);

    const handleChange = function updateLoginFormFields(event) {
        setLoginForm({...loginForm, [event.target.name]: event.target.value})
    };

    const dispatchLocalAuthInfo = useCallback(async function dispatchLocalAuthInfo({name, roles = []}) {
        const response = await axios.get(api_routes.ping.endpoint);
        if (roles.length === 0 && !isEmpty(authenticationService.getToken())) {
            roles = jwt_decode(authenticationService.getToken()).authorities;
        }

        // noinspection NestedFunctionCallJS
        dispatch(setSystemInformation(response.data));
        // noinspection NestedFunctionCallJS
        dispatch(setAuthenticated(true));
        // noinspection NestedFunctionCallJS
        dispatch(setCurrentUser({username: name, roles}));
    }, [dispatch]);

    const successfulPasswordUpdate = useCallback(async function successfullyChangedOTP(username, password) {
        const {roles} = await authenticationService.login({username, password});
        await dispatchLocalAuthInfo({name: username, roles});
        history.push(routes.home.path);
    }, [dispatchLocalAuthInfo, history]);

    const handleSignin = async function signInToSwiftGatewayWebApp(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const {username: name, roles, resetPassword} = await authenticationService.login({
                username: loginForm.username,
                password: loginForm.password,
                queryParams: `?sub=${query.get('sub')}&iss=${query.get('iss')}&name=${query.get('name')}`
            });
            if (resetPassword) {
                setPerformPasswordReset(resetPassword);
            } else {
                await dispatchLocalAuthInfo({name, roles});
                history.push(routes.home.path);
            }
        } catch (error) {
            if (!isEmpty(error.response)
                && (error.response.status === httpStatus.badRequest || error.response.status === httpStatus.unAuthorized)) {
                toast.notify(({onClose}) => <CustomAlert type='error'
                                                         message={error.response.data.error_description} onClose={onClose}/>);
            } else {
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message='Something went wrong! Check that the server is running.' onClose={onClose}/>)
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            {
                performPasswordReset && <UpdatePassword username={loginForm.username} currentPassword={loginForm.password}
                             onSuccess={successfulPasswordUpdate} />
            }
            {
                !performPasswordReset && (
                    <Grid container justifyContent='center' alignItems='center'
                          className='login-form'>
                        <StyledFormWrapper>
                            <StyledFormLogo>
                                <StyledLogo src={theme.clientBranding.logo.fullImage}
                                            alt={theme.clientBranding.logo.alt}/>
                            </StyledFormLogo>
                            <StyledWarningMessage>
                                There is an existing account with your username. Please provide the password to link the account.
                            </StyledWarningMessage>
                            <Box sx={{ width: '100%' }}>
                                <form onSubmit={handleSignin}>
                                    <FormControlInput width='100' required name='username' value={loginForm.username}
                                                      label='Username' disabled/>
                                    <PasswordField width={'100'} required password={loginForm.password} label='Password'
                                                   onChange={handleChange}/>
                                    <CircularProgressButton label='Sign In' variant='contained' size='medium' mt={2}
                                                            type='submit' inProgress={isLoading}/>
                                </form>
                            </Box>
                        </StyledFormWrapper>
                    </Grid>
                )
            }
        </>
    );
}