import * as React from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    Drawer,
    Grid,
    Hidden,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Toolbar
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import routes from '../../util/routes';
import {isEmpty} from '../../util/helpers';
import {Role} from '../auth/accessControl/role';
import AccessControl from '../auth/accessControl/AccessControl';
import {grey} from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {authenticationService} from '../../container/auth/authenticationService';
import {setAuthenticated, setCurrentUser} from '../../action';
import HelpSystemComponent from '../HelpSystemComponent';
import DesktopTopBarNavigation from './DesktopTopBarNavigation';
import MobileTopBarNavigation from './MobileTopBarNavigation';

export const drawerWidth = 240;
export const drawerColor = '#f7f8f9';

export const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.palette.navbar.main};
`;

export const StyledNavLogo = styled.img`
  height: 45px;
  margin-right: .75em;
`;

export const StyledNavText = styled.img`
  height: 45px;
`;

export const StyledMenuGridItem = styled(Grid)`
  margin-left: 16px;
`;

export const StyledMenuButton = styled(Button)`
  text-transform: none;
  color: ${grey[600]};
`;

export const StyledFlexGridItem = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const StyledNavLink = styled(NavLink)`
  padding-bottom: 20px;
  margin: 0 16px;
  text-decoration: none;
  color: ${props => props.theme.palette.navbar.text};
  font-size: ${props => props.theme.spacing(2)};
  font-weight: 700;

  &.active {
    color: ${props => props.theme.palette.primary.main};
    border-bottom: 3px solid ${props => props.theme.palette.secondary.main};
  }

  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 0.85rem;
`;

export const StyledChangePasswordLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

const SwiftStyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${props => props.theme.palette.navbar.text};
  font-size: ${props => props.theme.spacing(2)};
  font-weight: 700;

  &.active {
    & .MuiListItemIcon-root {
      color: ${props => props.theme.palette.primary.main};
    }
  ;
    color: ${props => props.theme.palette.primary.main};
    border-bottom: 3px solid ${props => props.theme.palette.secondary.main};
  }

  &:hover {
    & .MuiListItemIcon-root {
      color: ${props => props.theme.palette.primary.main};
    }
  ;
    color: ${props => props.theme.palette.primary.main};
  }
`;

function ResponsiveDrawer(props) {
    const [openHelpSystemComponent, setOpenHelpSystemComponent] = useState(false);
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(function getCurrentUserForNavigation(state) {
        return state.currentUser
    });

    const handleSignOut = async function signOut() {
        let user = {username: ''};
        await authenticationService.logout();
        // noinspection NestedFunctionCallJS
        dispatch(setCurrentUser(user));
        // noinspection NestedFunctionCallJS
        dispatch(setAuthenticated(false));
        history.push(routes.home.path);
    };

    const activeRoute = (routeName) => {
        const pathParts = (location.pathname).split('/');
        return pathParts[1] === routeName;
    }


    const helpTextIconButton = (
        <IconButton sx={{mr: 1, color: grey[600]}}
                    onClick={() => setOpenHelpSystemComponent(!openHelpSystemComponent)}
                    size='small'>
            <InfoOutlinedIcon/>
        </IconButton>
    );

    const topBar = (
        <StyledAppBar elevation={0} component='nav' variant='outlined' theme={theme}>
            <Toolbar>
                <Grid container justifyContent='space-between' direction='row' alignItems='center'>
                    <StyledFlexGridItem item>
                        <Grid container item direction='row' justifyContent='space-between' alignItems='center'>
                            <NavLink to={routes.home.path}>
                                <Grid container alignItems='center' wrap='nowrap'>
                                    <StyledNavLogo
                                        src={theme.clientBranding.logo.navbarBrandImage}
                                        alt={theme.clientBranding.logo.alt}
                                        className='logo'/>
                                    <Hidden mdDown>
                                        {!isEmpty(theme.clientBranding.logo.navbarBrandTextImage) &&
                                            <StyledNavText
                                                src={theme.clientBranding.logo.navbarBrandTextImage}
                                                alt={theme.clientBranding.logo.alt} className='text'/>
                                        }
                                    </Hidden>
                                </Grid>
                            </NavLink>
                        </Grid>
                    </StyledFlexGridItem>

                    <Hidden lgDown>
                        <DesktopTopBarNavigation theme={theme} handleSignOut={handleSignOut}
                                                 currentUser={currentUser} helpTextIconButton={helpTextIconButton}/>
                    </Hidden>

                    <Hidden lgUp>
                        <MobileTopBarNavigation currentUser={currentUser} handleSignOut={handleSignOut}
                                                helpTextIconButton={helpTextIconButton}/>
                    </Hidden>

                </Grid>
            </Toolbar>
        </StyledAppBar>
    );

    const drawer = (
        <>
            <Toolbar/>
            <MenuList>
                <AccessControl allowedRoles={[Role.USER, Role.ADMIN]}>
                    <SwiftStyledNavLink isActive={() => activeRoute('filesystem') || activeRoute('fs')}
                                        theme={theme} to={routes.filesystemfunc.path('')} key={'filesystem'}>
                        <ListItemButton selected={activeRoute('filesystem') || activeRoute('fs')}>
                            <ListItemIcon>
                                <FolderOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={routes.filesystem.menuTitle}/>
                        </ListItemButton>
                    </SwiftStyledNavLink>
                </AccessControl>
                <AccessControl allowedRoles={[Role.ADMIN]}>
                    <SwiftStyledNavLink theme={theme} to={routes.users.path} key={'users'}>
                        <ListItemButton selected={activeRoute('users')}>
                            <ListItemIcon>
                                <PeopleOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary={routes.users.menuTitle}/>
                        </ListItemButton>
                    </SwiftStyledNavLink>
                </AccessControl>
                <AccessControl allowedRoles={[Role.ADMIN]}>
                    <SwiftStyledNavLink theme={theme} to={routes.settings.path} key={'settings'}>
                        <ListItemButton selected={activeRoute('settings')}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={routes.settings.menuTitle}/>
                        </ListItemButton>
                    </SwiftStyledNavLink>
                </AccessControl>
            </MenuList>
        </>
    );

    const leftSideBar = (
        <AccessControl allowedRoles={[Role.ADMIN]}>
            <Box
                component='nav'
                sx={{width: {md: drawerWidth}, flexShrink: {sm: 0}, display: {md: 'none', lg: 'inherit'}}}
                aria-label='navigation'
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant='permanent'
                    sx={{
                        display: {xs: 'none', md: 'block'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: drawerColor,
                            zIndex: 'unset'
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </AccessControl>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            {topBar}
            {leftSideBar}
            <Box sx={{flexGrow: 1, width: '100%', flex: '0 0 1', overflow: 'hidden',}}>
                <Toolbar/>
                <HelpSystemComponent open={openHelpSystemComponent} setOpen={setOpenHelpSystemComponent}>
                    {props.children}
                </HelpSystemComponent>
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {};

export default ResponsiveDrawer;
