import React from 'react';
import {useLocation} from 'react-router-dom';

// A custom hook that builds on useLocation to parse the query string for you.
// https://v5.reactrouter.com/web/example/query-parameters

// noinspection FunctionNamingConventionJS
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
