import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, MenuItem, useTheme } from '@mui/material';
import FormControlInput from '../../common/FormControlInput';
import CircularProgressButton from '../../common/CircularProgressButton';
import FormControlSelect from '../../common/FormControlSelect';
import { Role, RoleDisplayDict, UserType } from '../../auth/accessControl/role';
import { isUndefined } from '../../../util/helpers';

// noinspection FunctionNamingConventionJS
function IdentityProviderForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const componentMounted = useRef(true);

    useEffect(() => {
        setLoadingSubmit(false);
        return () => {
            componentMounted.current = false
        }
    }, [props.errors]);

    const handleSubmit = async function handleCreateIdpSubmitEvent(event) {
        event.preventDefault();
        setLoadingSubmit(true);
        await props.handleSubmit(event);
        if (componentMounted.current) {
            setLoadingSubmit(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            {!isUndefined(props.identityProvider.id) && <Box mt={2}>
                <FormControlLabel label='Enabled' labelPlacement='end'
                                  control={<Checkbox checked={props.identityProvider.enabled} color='primary'
                                                     onChange={(event) => props.handleCheckboxChange('enabled', event)}/>}
                />
            </Box>}
            <FormControlInput value={props.identityProvider.name} width='100'
                              label='Identity Provider Display Name'
                              name='name' placeholder='Enter unique name'
                              onChange={props.handleChange}
                              helperText='Name used to identify this identity provider.'
                              errorMessage={props.errors.name}
            />
            <Box mt={2} mb={1}>
                <FormControlSelect name='type' type='select' defaultValue={UserType.ROLE_SWIFT} width={'100'}
                                   label={'Default User Role'} value={props.identityProvider.type}
                                   onChange={props.handleChange} margin={'none'}
                                   helperText={'Role given to new users created from this identity provider.'}>
                    <MenuItem value={UserType.ROLE_SWIFT}>{RoleDisplayDict[Role.USER]}</MenuItem>
                    <MenuItem value={UserType.ROLE_ADMIN}>{RoleDisplayDict[Role.ADMIN]}</MenuItem>
                </FormControlSelect>
            </Box>
            <FormControlInput value={props.identityProvider.notes} width='100' label='Notes'
                              name='notes' placeholder='Enter notes (optional)' onChange={props.handleChange}
                              inputProps={{maxLength: 1000}} showCount
                              helperText='Notes about this identity provider.'
                              errorMessage={props.errors.notes} multiline
            />
            <FormControlInput value={props.identityProvider.issuerUri} width='100' label='Issuer URI'
                              name='issuerUri' placeholder='Enter issuer URI'
                              onChange={props.handleChange}
                              helperText='Enter issuer URI.'
                              errorMessage={props.errors.issuerUri}
            />
            <FormControlInput value={props.identityProvider.clientId} width='100' label='Client ID'
                              name='clientId' placeholder='Enter client id'
                              onChange={props.handleChange}
                              helperText='Enter the client ID from the IdP.'
                              errorMessage={props.errors.clientId}
            />
            <FormControlInput value={props.identityProvider.clientSecret} width='100' label='Client Secret'
                              name='clientSecret' placeholder='Enter client secret'
                              onChange={props.handleChange}
                              helperText='Enter the client secret from the IdP.'
                              errorMessage={props.errors.clientSecret}
            />
            <FormControlInput value={props.identityProvider.nameAttribute} width='100' label='Username Attribute'
                              name='nameAttribute' placeholder='Enter username attribute'
                              onChange={props.handleChange}
                              helperText={'Set the attribute name used to access the user\'s name from the user info response. ' +
                                  'This attribute is used to match an existing user or to create a new user.'}
                              errorMessage={props.errors.nameAttribute}
            />
            <FormControlInput value={props.identityProvider.registrationId} width='100' label='Registration Id'
                              name='registrationId' placeholder='Enter registration id'
                              onChange={props.handleChange}
                              helperText={'Set registration id.'}
                              errorMessage={props.errors.registrationId}
            />
            <FormControlInput value={props.identityProvider.scopes} label='Scopes' width={'100'}
                              name='scopes' placeholder='openid'
                              onChange={props.handleChange}
                              helperText={'Set a list of extra OpenID Connect scopes separated by comma. The \'openid\' scope is always included.'}
                              errorMessage={props.errors.scopes}
            />
            <Box display='flex'>
                <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                        label='Save' mt={2} inProgress={loadingSubmit} fullWidth={false}/>
                <CircularProgressButton label='Cancel' theme={theme} type='reset' size='small' mt={2}
                                        onClick={props.handleCancel} variant='text'/>
            </Box>
        </form>
    );
}

IdentityProviderForm.propTypes = {
    identityProvider: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
};

IdentityProviderForm.defaultProps = {
};

export default IdentityProviderForm;
