import qs from 'querystring';
import axios from 'axios';
import api_routes from '../../util/api_routes';

const {clientid, clientsecret} = window._env_ || {};
const CLIENT_ID = clientid || process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = clientsecret || process.env.REACT_APP_CLIENT_SECRET;

export const KEY_USER_LOGIN_METHOD = 'user-login-method';
export const VALUE_USER_LOGIN_METHOD_SSO = 'sso';
export const VALUE_USER_LOGIN_METHOD_SLINK = 'slink';

export const authenticationService = {
    login,
    logout,
    clearTokenInfo,
    setToken,
    setName,
    token: localStorage.getItem('swiftgw'),
    username: localStorage.getItem('swiftgw-user'),
    getToken: () => localStorage.getItem('swiftgw'),
    getUsername: () => localStorage.getItem('swiftgw-user'),
};

async function login({username, password, queryParams = ''}) {
    let data = qs.stringify({
        'grant_type': 'password',
        'username': username,
        'password': password,
        'scope': 'read write',
    });

    let response = await axios.request({
        url: `${api_routes.auth.endpoint}${queryParams}`,
        method: 'post',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: CLIENT_ID,
            password: CLIENT_SECRET,
        },
        data: data
    });

    const {access_token: token, username: name, resetPassword, roles} = response.data;

    if (!resetPassword) {
        localStorage.setItem('swiftgw', token);
        localStorage.setItem('swiftgw-user', name);
    }

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return {token, username: name, resetPassword, roles: roles.map(object => object.authority)};
}

async function setToken(token){
    localStorage.setItem('swiftgw', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function setName(name){
    localStorage.setItem('swiftgw-user', name);
}


function clearTokenInfo() {
    localStorage.removeItem('swiftgw');
    localStorage.removeItem('swiftgw-user');
}

async function logout() {
    let token = localStorage.getItem('swiftgw');
    let target = window.location.origin;
    delete axios.defaults.headers.common['Authorization'];
    let response;
    if (token) {
        response = await axios.post(`${api_routes.logout.endpoint}?token=${token}&target=${target}`);
    }
    clearTokenInfo();
    localStorage.removeItem(KEY_USER_LOGIN_METHOD);
    try {
        if (response?.data?.idpRedirect) {
            window.location.href = response.data.idpRedirect;
        } else {
            window.history.replaceState({}, null, target);
        }
    }
    finally {
        //Clear the swr cached data with a reload
        window.location.reload()
    }
}
