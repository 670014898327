import React, {useState} from 'react';
import PropTypes from 'prop-types';
import toast from 'toasted-notes';
import axios from 'axios';
import styled from 'styled-components';
import {Box, Grid, Typography,} from '@mui/material';
import CustomAlert from '../common/CustomAlert';
import FormControlInput from '../common/FormControlInput';
import CircularProgressButton from '../common/CircularProgressButton';
import {convertErrorsToObject} from '../../util/errorHandler';
import {isEmpty} from '../../util/helpers';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import PasswordField from "../common/PasswordField";
import { useTheme } from "@mui/material/styles";

const formText = {
  usernamePlaceholder: 'Enter username',
  passwordPlaceholder: 'Enter complex password',
  passwordRequirements: 'At least one capital letter, one lower case letter, one number, and one special character',
};

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledFormLogo = styled.div`

`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
`;

const StyledGridContainer = styled(Grid)`
  height: 100%;
`;

const StyledGridLeftItem = styled(Grid)`
  height: 100%;
  background-color: rgb(216, 216, 216);
`;

const StyledFormContent = styled.div`
  text-align: center;
  color: #333;
  margin: 0 0 40px;
`;

const initSignUpForm = {
  username: '',
  password: '',
}

// noinspection FunctionNamingConventionJS
function SignUp(props) {
  const [signUpForm, setSignUpForm] = useState(initSignUpForm);
  const [signUpErrors, setSignUpErrors] = useState(initSignUpForm);

  const [signingUp, setSigningUp] = useState(false);
  const theme = useTheme();

  const handleChange = function updateSignUpFormFields(event) {
    setSignUpForm({...signUpForm, [event.target.name]: event.target.value})
  };

  const handleSignUp = async function signUpForSFTPGatewayWebApp(event) {
    setSigningUp(true);
    event.preventDefault();
    try {
      await axios.post(api_routes.firstUserConfiguration.endpoint, {
        username: signUpForm.username.trim(),
        password: signUpForm.password,
      })
      toast.notify(({onClose}) => <CustomAlert message='User created! Please sign in to continue.'
                                               onClose={onClose}/>);
      props.onSuccess();
    } catch (error) {
      if (error.response.status === httpStatus.notFound) {
        toast.notify(({onClose}) => <CustomAlert type='error' message='Something went wrong.' onClose={onClose}/>);
      } else if (error.response.status === httpStatus.badRequest) {
        let tempErrors = convertErrorsToObject(error.response);
        setSignUpErrors(tempErrors);
      }
      setSigningUp(false);
    }
  };

  const preparePasswordErrorMessage = function preparePasswordErrorMessageOnSignUpPage() {
    let finalResult = null;
    if (!isEmpty(signUpErrors.password)) {
      let passwordErrors = (signUpErrors.password).split('.');
      finalResult = passwordErrors.map(str => {
        let result = null;
        if (!isEmpty(str)) {
          result = <div>{str}.</div>
        }
        return result;
      })
    }
    return finalResult;
  };

  return (
      <StyledGridContainer container justifyContent='center' alignItems='center'
                           className='first-sign-in-form'>
        <StyledGridLeftItem item container justifyContent='center' alignItems='center'
                            xs={7}>
          <StyledFormWrapper>
            <StyledFormLogo>
              <StyledLogo src={theme.clientBranding.logo.fullImage}
                          alt={theme.clientBranding.logo.alt}/>
            </StyledFormLogo>
          </StyledFormWrapper>
        </StyledGridLeftItem>
        <Grid item container justifyContent='center' alignItems='center' xs={5}>
          <StyledFormWrapper className='form-wrapper'>
            <StyledFormContent>
              <Typography variant='h4'>Welcome!</Typography>
              <Typography variant='h5'>Create your account</Typography>
            </StyledFormContent>
            <Box sx={{ width: '100%' }}>
              <form onSubmit={handleSignUp} noValidate>
                <FormControlInput name='username' value={signUpForm.username}
                                  width='100' required errorMessage={signUpErrors.username}
                                  placeholder={formText.usernamePlaceholder}
                                  label='Username' onChange={handleChange}
                />
                <PasswordField password={signUpForm.password} width='100' required
                               placeholder={formText.passwordPlaceholder} label='Password'
                               errorMessage={preparePasswordErrorMessage()}
                               tooltip={formText.passwordRequirements}
                               onChange={handleChange}/>
                <CircularProgressButton type='submit' label='Create Account'
                                        width='100' inProgress={signingUp}
                                        buttonTextTransform='none' mt={2}/>
              </form>
            </Box>
          </StyledFormWrapper>
        </Grid>
      </StyledGridContainer>
  )
}

SignUp.propTypes = {
  onSuccess: PropTypes.func,
};

export default SignUp;
