import styled from 'styled-components';
import {Breadcrumbs, Button, IconButton, Link, Select, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {isEmpty} from '../../../util/helpers';
import {grey} from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';

/* --- StorageLink Tables --- */
export const StyledTableHead = styled(TableHead)`
  background-color: ${props => {
    if (!isEmpty(props.disabled) && props.disabled) {
      return grey[200];
    }
    return props.theme.palette.primary.main + '0a';
  }};
`;

export const StyledTableHeadCell = styled(TableCell)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  color: ${props => {
    if (!isEmpty(props.disabled) && props.disabled) {
      return grey[500];
    }
    return props.theme.palette.primary.main
  }};
  border-color: ${props => {
    if (!isEmpty(props.disabled) && props.disabled) {
      return grey[500];
    }
    return props.theme.palette.primary.main;
  }};
  padding: .875rem 1rem;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }

  &:nth-of-type(even) {
    background-color: ${props => {
      if (!isEmpty(props.disabled) && props.disabled) {
        return grey[100];
      }
      return props.theme.palette.action.hover;
    }};
  }
`;

export const StyledTableRowWithForm = styled(StyledTableRow)`
  background-color: ${props => props.theme.palette.secondary.main + '26'} !important;
`;

export const StyledTableCell = styled(TableCell)`
  padding: .875rem 0 .875rem 1rem;
  vertical-align: baseline;
  color: ${props => {
    if (!isEmpty(props.disabled) && props.disabled) {
      return grey[500];
    }
    return 'inherit';
  }};
`;

export const StyledEditIconButton = styled(IconButton)`
  margin-right: 8px;
`;

export const StyledEditIcon = styled(EditIcon)`
  color: ${props => {
    if (!isEmpty(props.disabled) && props.disabled) {
      return grey[500];
    }
    return props.theme.palette.primary.main;
  }};
`;

export const StyledTableRowInlineEditingButton = styled(Button)`
  min-width: auto;
  padding: 2px 6px;
`;

/* --- Select without the background --- **/
export const StyledSelect = styled(Select)`
  color: #333;
  font-size: .875rem;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 3px;
  padding-right: 12px;
`;

/* --- Forms --- */
export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${props => props.theme.spacing(2)};
  cursor: pointer;
`;

export const StyledBreadcrumbLink = styled(Link)`
  display: flex;
`;

export const StyledBreadcrumbIcon = styled(ArrowBackIosIcon)`
  margin-right: ${props => props.theme.spacing(0.5)};
  width: 20px;
  height: 20px;
  align-self: center;
`;

/** Main Page Table **/
export const StyledNavControls = styled.div`
  padding: 16px;
  border: 1px solid;
  border-color: ${props => props.theme.palette.primary.main + '73'};
  background-color: ${props => props.theme.palette.primary.main + '0a'};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: -1px -1px 0;
  position: relative !important;
`;

export const StyledNavControlsWithoutBackground = styled.div`
  padding: 16px;
  border-bottom: 1px solid;
  border-color: ${props => props.theme.palette.divider};
`;

export const NoOverflowTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledIsActiveDropZone = styled.div`
  ${({theme, isActive, notUploadable}) => isActive ? `
            position: relative;
            border: 1px dashed ${theme.palette.primary.main};
            background-color: ${theme.palette.primary.main}25;
            border-radius: 5px;
            &:before {
                content: "Upload files/folders here";
                position: absolute;
                display: inline;
                top: 0%;
                left: 50%;
                white-space: pre;
                transform: translate(-50%, -21px);
                background-color: ${theme.palette.primary.main};
                padding: 8px;
                border-radius: 25px;
                color: white;
            }` : notUploadable ? `
            position: relative;
            border: 1px dashed #c62828;
            background-color: #c6282825;
            border-radius: 5px;
            &:before {
                content: "File upload is not available";
                position: absolute;
                display: inline;
                top: 0;
                left: 50%;
                white-space: pre;
                transform: translate(-50%, -21px);
                background-color: #c62828;
                padding: 8px;
                border-radius: 25px;
                color: white;
          }` : ``}
`;