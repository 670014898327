import {createTheme} from '@mui/material/styles';
import fullImage from './assets/storagelink-full-logo.png';
import navbarBrandImage from './assets/storagelink-full-logo.png';
import manifest from './assets/site.webmanifest';
import appleTouchIcon from './assets/apple-touch-icon.png';
import favicon16x16 from './assets/favicon-16x16.png';
import favicon32x32 from './assets/favicon-32x32.png';
import androidChromeIcon192x192 from './assets/android-chrome-192x192.png';

export default function createMyTheme(options) {

    let {clientBranding: customClientBranding, ...otherCustomOptions} = options;

    const branding = {
        logo: {
            fullImage,
            alt: "StorageLink",
            navbarBrandImage,
        },
        pageTitle: "StorageLink",
        manifest,
        favIcons: [
            // {rel: "apple-touch-icon", sizes: "180x180", href:appleTouchIcon},
            // {rel: "icon", type: "image/png", sizes: "192x192", href: androidChromeIcon192x192},
            {rel: "icon", type: "image/png", sizes: "32x32", href: favicon32x32},
            // {rel: "icon", type: "image/png", sizes: "16x16", href: favicon16x16},
        ]
    }

    // noinspection DuplicatedCode
    return createTheme({
        clientBranding: {
            ...branding,
            ...customClientBranding
        },
        ...otherCustomOptions,
    })
}
