import React, {useState} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router';
import {Box, Grid, Typography} from '@mui/material';
import PasswordField from '../common/PasswordField';
import CircularProgressButton from '../common/CircularProgressButton';
import HelpSystemContext from '../../context/HelpSystemContext';
import routes from '../../util/routes';
import httpStatus from '../../util/http_status';
import {convertErrorsToObject} from '../../util/errorHandler';
import api_routes from '../../util/api_routes';
import {authenticationService} from "../../container/auth/authenticationService";

// noinspection FunctionNamingConventionJS
function ChangePasswordComponent() {
    const history = useHistory();

    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [changePasswordForm, setChangePasswordForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const handleChange = function updateChangePasswordFormFields(event) {
        setChangePasswordForm(
            {...changePasswordForm, [event.target.name]: event.target.value})
    };

    const handleCancel = function handleResettingChangePasswordForm() {
        history.goBack();
    };

    const changePassword = function changePassword({currentPassword, newPassword}) {
        return axios.patch(`${api_routes.user.endpoint}/password`, {
            currentPassword,
            newPassword
        });
    };

    const handleSubmit = async function submitChangePassword(event) {
        event.preventDefault();
        if (changePasswordForm.newPassword === changePasswordForm.confirmNewPassword) {
            setConfirmPasswordError('');
            setIsLoading(true);
            try {
                await changePassword({
                    currentPassword: changePasswordForm.currentPassword,
                    newPassword: changePasswordForm.newPassword
                });
                await authenticationService.logout();
            } catch (error) {
                setIsLoading(false);
                if (error.response.status === httpStatus.unAuthorized) {
                    setCurrentPasswordError('Wrong password. Try again.')
                    setNewPasswordError('')
                } else if (error.response.status === httpStatus.badRequest) {
                    let tempErrors = convertErrorsToObject(error.response);
                    setCurrentPasswordError(tempErrors?.currentPassword);
                    setNewPasswordError(tempErrors?.newPassword);
                }
            }
        } else {
            setCurrentPasswordError('');
            setNewPasswordError('');
            setConfirmPasswordError("New passwords don't match");
        }
    };

    return <Box p={3}>
        <Typography variant='h5'>{routes.profile.menuTitle}</Typography>
        <HelpSystemContext.Consumer>
            {open =>
                <Grid container>
                    <Grid item xs={12} md={open ? 12 : 6} xl={open ? 6 : 4}>
                        <form onSubmit={handleSubmit}>
                            <PasswordField label='Current Password' width='100' name='currentPassword'
                                           password={changePasswordForm.currentPassword}
                                           onChange={handleChange}
                                           errorMessage={currentPasswordError}/>
                            <PasswordField label='New Password' width='100' name='newPassword'
                                           password={changePasswordForm.newPassword}
                                           onChange={handleChange}
                                           tooltip='At least one capital letter, one lower case letter, one number,
                                              and one special character'
                                           errorMessage={newPasswordError}/>
                            <PasswordField label='Confirm New Password' width='100' name='confirmNewPassword'
                                           password={changePasswordForm.confirmNewPassword}
                                           onChange={handleChange}
                                           errorMessage={confirmPasswordError}/>
                            <Box display='flex'>
                                <CircularProgressButton label='Update' variant='contained' size='small' mt={2}
                                                        type='submit' inProgress={isLoading}/>
                                <CircularProgressButton label='Cancel' variant='text' size='small'
                                                        ml={1} mt={2} onClick={handleCancel} type='reset'/>
                            </Box>

                        </form>
                    </Grid>
                </Grid>
            }
        </HelpSystemContext.Consumer>
    </Box>
}

export default ChangePasswordComponent;
