import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTheme} from '@mui/material/styles';
import {HelpOutline} from '@mui/icons-material';
import {FormControl, FormHelperText, Select, Tooltip, Typography} from '@mui/material';
import {isEmpty} from '../../util/helpers';
import {StyledTypographyLabel} from './FormControlInput';

const tooltipLeaveDelay = 100;
const StyledLabelTooltip = styled(Tooltip)`
  margin-left: 5px;
`;

const StyledSelect = styled(Select)`
  background-color: ${props => {
    let color;
    if (props.removebackground === 'true') {
      color = 'transparent';
    } else {
      color = 'rgba(0,0,0,0.02)';
    }
    return color;
  }};
  font-size: 0.875rem;
  padding-left: 0.875em;
  border-radius: 0.1275em;
  padding-right: 0.875em;
`;

const StyledFormControl = styled(FormControl)`
  width: ${function setWidth(props) {
    return props.width
  }}%;
`;

// noinspection FunctionNamingConventionJS
export default function FormControlSelect(props) {

    const theme = useTheme();

    return (
        <StyledFormControl variant={'standard'} error={props.error} width={props.width}
                           margin={props.margin}>
            {!isEmpty(props.label) &&
                <StyledTypographyLabel required={props.required} filled={'true'} color={theme.palette.text.primary}
                                       disabled={props.disabled} variant='body1'>
                    {props.label}
                    {(props.tooltip) &&
                        <StyledLabelTooltip title={<Typography variant={'body2'}>{props.tooltip}</Typography>}
                                            leaveDelay={tooltipLeaveDelay}
                                            placement='right'>
                            <HelpOutline/>
                        </StyledLabelTooltip>
                    }
                </StyledTypographyLabel>
            }
            <StyledSelect fullWidth id={props.name} native={props.native}
                          onChange={function handlechange(event) {
                              props.onChange(event)
                          }} defaultValue={props.defaultValue}
                          name={props.name} value={props.value} onBlur={props.onBlur}
                          required={props.required} disabled={props.disabled} onKeyDown={props.onKeyDown}
                          placeholder={props.placeholder}
            >
                {props.children}
            </StyledSelect>
            {!isEmpty(props.helperText) && isEmpty(props.errorMessage) &&
                <FormHelperText id={`${props.name}-helper-text`}>
                    {props.helperText}
                </FormHelperText>
            }
            {!isEmpty(props.errorMessage) &&
                <FormHelperText id={`${props.name}-error-text`}>
                    {props.errorMessage}
                </FormHelperText>
            }
        </StyledFormControl>
    )
};

FormControlSelect.propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    width: PropTypes.string,
    name: PropTypes.string,
    helperText: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    tooltip: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.any,
    native: PropTypes.bool,
    margin: PropTypes.string,
    placeholder: PropTypes.string,
};

FormControlSelect.defaultProps = {
    error: false,
    native: false,
    margin: 'normal',
};
