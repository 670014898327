import React from "react";
import useSWR from "swr";
import api_routes from "../util/api_routes";
import axios from "axios";
import {useParams} from "react-router-dom";

function useCloudCredentials() {

    const {cloudCredentialId} = useParams();
    const url = api_routes.cloudCredentials.endpoint.concat(`/${cloudCredentialId}`)

    async function fetchData(...args) {
        try {
            let response = await axios.get(args);
            return response.data
        } catch (e) {
            throw new Error(`Error fetching cloud credential ${cloudCredentialId} due to: ${e.message}`)
        }
    }

    const {data, error, isLoading, isValidating, mutate} = useSWR(url,
        fetchData,
        {
            fallback: {
                cloudProvider: '',
            }
        }
    )

    async function refresh() {
        await mutate()
    }

    return {
        credential: data,
        error,
        isLoading,
        isValidating,
        refresh
    }
}

useCloudCredentials.propTypes = {};

useCloudCredentials.defaultProps = {};


export default useCloudCredentials;