import React, {useState} from 'react';
import PropTypes from 'prop-types';
import FormControlInput from '../common/FormControlInput';
import md5 from 'md5';

// noinspection FunctionNamingConventionJS
function AzureCloudConnectionCredentialsForm(props) {

    const [displayNameManualChange, setDisplayNameManualChange] = useState(props.credentials.displayName !== "");


    const handleTextChange = (event) => {

        const { name, value } = event.target;
        let displayNameUpdate = {};

        if (name === 'displayName' && value) {
            setDisplayNameManualChange(true);
        } else if (name === 'displayName' && !value) {
            setDisplayNameManualChange(false);
        } else if (!displayNameManualChange && name === 'connectionString') {
            const regex = /AccountName=([^;]+)/;
            const match = value.match(regex);
            const accountName = match ? match[1] : null;
            displayNameUpdate = {displayName: `Azure - ${accountName} ${md5(value)}`};
        }

        props.handleChange({[event.target.name]: event.target.value, ...displayNameUpdate});
    }

    return (<>
        <FormControlInput value={props.credentials.connectionString}
                          label='Connection String'
                          name='connectionString'
                          helperText='This is the default connection string used for this cloud connection.'
                          placeholder={`${props.credentials.secretSet ? 'Hidden Value Is Set' : 'Enter connection string'}`}
                          onChange={handleTextChange}
                          errorMessage={props.errors.connectionString}
                          autoComplete="off"
                          width='100'
        />
        <FormControlInput value={props.credentials.displayName}
                          label='Credential Display Name'
                          name='displayName'
                          helperText='Name used to identify this cloud connection. By default the name is "Azure - {Account name from connection string}  {md5 sum of connection string}".'
                          placeholder='Enter unique name'
                          onChange={handleTextChange}
                          autoComplete='off'
                          errorMessage={props.errors.displayName}
                          width='100'
        />
    </>);
}

AzureCloudConnectionCredentialsForm.propTypes = {
    credentials: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
};

AzureCloudConnectionCredentialsForm.defaultProps = {
    credentials: {},
    errors: {},
    handleChange: () => {},
};

export default AzureCloudConnectionCredentialsForm;
