import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Hidden } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import HelpSystemContext from '../context/HelpSystemContext';
import HelpSystemDrawer from './HelpSystemDrawer';
import { useSelector } from 'react-redux';
import { hasAccess } from './auth/accessControl/AccessControl';
import { Role } from './auth/accessControl/role';
import routes from '../util/routes';

const drawerWidth = 360;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
        overflow: 'hidden',
    }),
);

// noinspection FunctionNamingConventionJS
function HelpSystemComponent(props) {

    const theme = useTheme();
    const location = useLocation();

    const [post, setPost] = useState('');
    const [fileName, setFileName] = useState('');
    const [provider, setProvider] = useState('');
    const [userTypePrefix, setUserTypePrefix] = useState('');
    const currentUser = useSelector(state => state.currentUser);

    const handleDrawerClose = () => {
        props.setOpen(false);
    };

    useEffect(() => {
        const regex = new RegExp(routes.filesystem.path);
        if (regex.test(location.pathname)) {
            setFileName('filesystem.md');
        } else {
            setFileName(location.pathname
                .replace(/^\/|\/$/g, '')
                .split('/')
                .join('_')
                .replace(/_[0-9]+/g, '') + '.md');
        }
    }, [location.pathname]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('provider')) {
            setProvider(`/${searchParams.get('provider')}`);
        } else {
            setProvider('');
        }
    }, [location.pathname, location.search]);

    useEffect(() => {
        if (hasAccess(currentUser.roles, [Role.ADMIN])) {
            setUserTypePrefix('/adminHelpDoc');
        }
    }, [currentUser.roles]);

    useEffect(() => {
        if (props.open) {
            import(`../helpDoc${userTypePrefix}${provider}/${fileName}`)
                .then(res => {
                    fetch(res.default)
                        .then(res => res.text())
                        .then(text => setPost(text.toString()))
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        }
    }, [props.open, fileName, provider, userTypePrefix]);

    return <>
        <Hidden mdDown>
            <Box sx={{display: 'flex'}}>
                <HelpSystemContext.Provider value={props.open}>
                    <Main open={props.open} className={props.open ? 'help-opened' : 'help-closed'}>
                        {props.children}
                    </Main>
                </HelpSystemContext.Provider>

                <Box style={{...(props.open && {paddingRight: theme.spacing(0)}), ...(!props.open && {pointerEvents: 'none'})}}>
                    <HelpSystemDrawer drawerAnchor='right' post={post}
                                      handleDrawerClose={handleDrawerClose} open={props.open}
                                      drawerWidth={drawerWidth}/>
                </Box>
            </Box>
        </Hidden>


        <Hidden mdUp>
            {!props.open &&
                <HelpSystemContext.Provider value={props.open}>
                    {props.children}
                </HelpSystemContext.Provider>
            }
            <HelpSystemDrawer drawerAnchor='top' post={post}
                              handleDrawerClose={handleDrawerClose} open={props.open} drawerWidth={'100%'}/>
        </Hidden>
    </>;
}

HelpSystemComponent.propTypes = {};

HelpSystemComponent.defaultProps = {};

export default HelpSystemComponent;
