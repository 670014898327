import {Redirect} from 'react-router-dom';
import {authenticationService} from './authenticationService';
import routes from '../../util/routes';
import React from 'react';
import useQuery from '../../hook/useQuery';

export default function TokenIngest() {
    let query = useQuery();

    authenticationService.setToken(query.get("token")).then();

    authenticationService.setName(query.get("name")).then();

    return <Redirect to={routes.home.path}/>;
}