import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Role } from '../auth/accessControl/role';
import AccessControl from '../auth/accessControl/AccessControl';
import UserPermissionsComponent from './UserPermissionsComponent';
import CloudConnectionComponent from '../cloudconnection/CloudConnectionComponent';
import TestConnectionResultTable from '../cloudconnection/TestConnectionResultTable';
import CloudConnectionCredentialsComponent, {
    cloudCredentialsOptions
} from '../cloudconnectioncredentials/CloudConnectionCredentialsComponent';
import { isUndefined } from '../../util/helpers';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import { INHERITED } from '../../util/cloud_providers';
import { convertErrorsToObject } from '../../util/errorHandler';
import CustomAlert from '../common/CustomAlert';
import FormControlInput from '../common/FormControlInput';
import _ from 'lodash';

// noinspection FunctionNamingConventionJS
const FolderForm = forwardRef((props, _ref) => {

    const {folder, folderName, showUserPermissions = true} = props;
    const {saveFolder, setErrors, setIsFolderFormBlocking} = props

    const { cache } = useSWRConfig();
    const history = useHistory();
    const location = useLocation();

    const initialFolderName = useRef(folderName);
    const initialUserPermissions = useRef();
    useEffect(() => {
        if(!initialUserPermissions.current){
            initialUserPermissions.current = _.cloneDeep(props.userPermissions);
        }
    }, [props.userPermissions])

    const [cloudProvider, setCloudProvider] = useState(INHERITED);

    const [cloudConnectionId, setCloudConnectionId] = useState();
    const [cloudCredentialsId, setCloudCredentialsId] = useState();
    const [cloudCredentialsInherited, setCloudCredentialsInherited] = useState();

    const [isFolderBlocking, setIsFolderBlocking] = useState(false);
    const [isConnectionBlocking, setIsConnectionBlocking] = useState(false);
    const [isCredentialBlocking, setIsCredentialBlocking] = useState(false);

    const cloudCredentialsStateRef = useRef();
    const cloudConnectionStateRef = useRef();

    const redirect = new URLSearchParams(location.search).get("redirect");

    useEffect(() => {
        if (!isUndefined(folder.cloudConnectionId) && folder.exists && !props.isCreatingFolder) {
            setCloudProvider(folder.cloudProvider);
            setCloudConnectionId(folder.cloudConnectionId);
        } else {
            setCloudProvider(INHERITED);
            setCloudConnectionId(undefined);
        }
    }, [folder.cloudConnectionId, folder.cloudProvider, folder.exists, props.isCreatingFolder]);

    useEffect(() => {
        const hasFolderChanged = (initialFolderName.current !== folderName) || (!_.isEqual(initialUserPermissions.current, props.userPermissions));
        setIsFolderBlocking(hasFolderChanged);
    }, [folderName, initialFolderName, initialUserPermissions, props.userPermissions])

    useEffect(() => {
        // The props.isFolderFormBlocking gets updated one frame late, which causes the prompt to trigger on saves.
        // This bug is masked because we only set submitting to false on errors.
        setIsFolderFormBlocking(isFolderBlocking || isConnectionBlocking || (isCredentialBlocking && cloudProvider !== INHERITED));
    }, [isFolderBlocking, isConnectionBlocking, isCredentialBlocking, setIsFolderFormBlocking, cloudProvider]);

    const handleSubmit = useCallback(async function handleFolderSubmitEvent(event) {
        event.preventDefault();
        setErrors({});
        let cloudCredentialId = null
        let credentialOption = null
        try {
            if (cloudCredentialsStateRef.current) {
                let {
                    cloudCredentialsId,
                    credentialsOption,
                } = cloudCredentialsStateRef.current.getCredentials();
                credentialOption = credentialsOption;
                cloudCredentialId = cloudCredentialsId;
                if (credentialsOption === cloudCredentialsOptions.NEW) {
                    const cloudCredentialsResponse = await cloudCredentialsStateRef.current.save();
                    cloudCredentialId = cloudCredentialsResponse.cloudCredentialsId;
                }
                setIsCredentialBlocking(false);
            }
            const folderResponse = await saveFolder({cloudConnectionId});
            setIsFolderBlocking(false);
            if(cloudConnectionStateRef.current) {
                const folderPath = folderResponse?.data?.absolutePath;
                if(!isUndefined(folderPath)) {
                    await cloudConnectionStateRef.current.save(folderPath, cloudCredentialId, credentialOption);
                    //Clear the filesystem swr cache
                    [...cache.keys()].forEach((key) => cache.delete(key))
                }
                setIsConnectionBlocking(false);
            }
            redirect? history.push(redirect) : history.goBack();
        } catch (error) {
            //Errors thrown
            if (error && error.response && error.response.status === httpStatus.forbidden) {
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={error.response.data.message} onClose={onClose}/>);
            } else if (error && error.response && error.response.status === httpStatus.conflict) {
                // Conflict in this case returns the following message: "{"message":"value too long for type character varying(255)","errors":[]}"
                setErrors({folderName: 'Folder name must be no more than 255 characters long.'});
            } else if(error && error.response && error.response.data && error.response.data.message){
                setErrors(convertErrorsToObject(error.response));
            } else {
                console.error("Error while saving folder", error);
            }
            throw error;
        }
    }, [setErrors, saveFolder, cloudConnectionId, redirect, history, cache] );

    const testConnectivity = useCallback(async () => {
        if(cloudConnectionStateRef.current){
            const {cloudConnection, connectionOption}  = cloudConnectionStateRef.current.getCloudConnection();
            if(cloudConnection.id){
                //Use the update cloud connection endpoint if the connection exists
                const result = await axios.get(`${api_routes.cloudConnection.endpoint}/${cloudConnection.id}`, {
                    params: {updateConnectivity: true}
                });
                return {data: result.data.connectivity}
            } else {
                let cloudCredential = {};
                if (cloudCredentialsStateRef.current) {
                    cloudCredential = cloudCredentialsStateRef.current.getCredentials()
                }

                //Use the test cloud connection endpoint if the connection does not exist
                try {
                    return await axios.post(`${api_routes.cloudConnectionTest.endpoint}`, {
                        credential: {
                            accessKeyId: cloudCredential.accessKeyId,
                            accessSecret: cloudCredential.accessSecret,
                            jwtToken: cloudCredential.jwtToken,
                            connectionString: cloudCredential.connectionString,
                        },
                        basePrefix: cloudConnection.basePrefix,
                        encryptionId: cloudConnection.encryptionId,
                        encryptionType: cloudConnection.encryptionType,
                        region: cloudConnection.region,
                        cloudCredentialId: cloudCredential.cloudCredentialsId,
                        useInstanceCredentials: cloudCredential.credentialsOption === cloudCredentialsOptions.INSTANCE,
                        cloudProvider: connectionOption
                    });
                } catch (error) {
                    setErrors(convertErrorsToObject(error.response));
                    return {
                        data: {
                            canList: false,
                            canRead: false,
                            canWrite: false
                        }
                    };
                }
            }
        }
    }, [setErrors]);

    useImperativeHandle(_ref, () => ({
        handleSubmit: handleSubmit,
    }));

    return (<>
            {!isUndefined(folderName) &&
                <FormControlInput value={folderName} width='100' label='Folder Name' name='folderName'
                                  placeholder='Enter a folder name' autoFocus
                                  onChange={event => props.setFolderName(event.target.value)}
                                  errorMessage={props.errors.folderName}
                                  heperText='Name of the new folder. Nested folders are not allowed.'/>
            }
            <AccessControl allowedRoles={[Role.ADMIN]}>
                {showUserPermissions &&
                    <UserPermissionsComponent
                        userPermissions={props.userPermissions}
                        handleChange={event => props.setUserPermissions(event.target.value)}
                    />
                }
                <CloudConnectionComponent
                    ref={cloudConnectionStateRef}
                    connectionOption={cloudProvider}
                    onCloudProviderChange={(value) => setCloudProvider(value)}
                    disableInheritedOption={props.folder.isRootFolder && isUndefined(folderName)}
                    inheritedCloudProvider={props.folder?.inheritedCloudProvider}
                    cloudConnectionId={cloudConnectionId}
                    setCloudCredentialsId={setCloudCredentialsId}
                    setCloudCredentialsInherited={setCloudCredentialsInherited}
                    inheritedCloudAbsolutePath={`${props.folder?.inheritedCloudAbsolutePath}/${!isUndefined(folderName) ? folderName : ''}`}
                    inheritedCloudConnectionSourceFolderPath={props.folder?.inheritedCloudFolder}
                    errors={props.errors}
                    setIsConnectionBlocking={setIsConnectionBlocking}
                >
                </CloudConnectionComponent>
                {cloudProvider !== INHERITED && <>
                    <CloudConnectionCredentialsComponent cloudProvider={cloudProvider}
                                                         cloudCredentialsId={cloudCredentialsId}
                                                         cloudCredentialsInherited={cloudCredentialsInherited}
                                                         ref={cloudCredentialsStateRef}
                                                         errors={props.errors}
                                                         setIsCredentialBlocking={setIsCredentialBlocking}
                    />
                    <TestConnectionResultTable test={testConnectivity} setErrors={(error) => setErrors(convertErrorsToObject(error.response))}/>
                    </>
                }
            </AccessControl>
        </>
        );
})

FolderForm.propTypes = {
    folderName: PropTypes.string,
    userPermissions: PropTypes.array,
    setUserPermissions: PropTypes.func,
    showUserPermissions: PropTypes.bool,
    setFolderName: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    folder: PropTypes.object,
    saveFolder: PropTypes.func,
    isCreatingFolder: PropTypes.bool,
    path: PropTypes.string,
    setIsFolderFormBlocking: PropTypes.func,
};

FolderForm.defaultProps = {
    errors: {},
    isCreatingFolder: false
};

export default FolderForm;
