import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormControlInput from '../../common/FormControlInput';
import AdvancedSectionExpansion from '../../common/AdvancedSectionExpansion';
import { isEmpty } from '../../../util/helpers';

export function getContainerUrl(accountName, endpoint, containerName) {
  return `https://${accountName}.${endpoint}/${containerName}`;
}

// noinspection FunctionNamingConventionJS
function AzureCloudConnectionForm(props) {

  const {onCloudConnectionChange, basePrefix} = props;
  const initialAzureStorageState = useMemo(() => ({
    accountName: '',
    containerName: '',
    endpoint: 'blob.core.windows.net',
  }), []);
  const [azureStorage, setAzureStorage] = useState(initialAzureStorageState);

  const [cloudConnection, setCloudConnection] = useState({basePrefix: basePrefix,});

  const getContainerUrlLocal = useCallback(function getContainerUrlLocal() {
    return getContainerUrl(azureStorage.accountName, azureStorage.endpoint, azureStorage.containerName);
  }, [azureStorage]);

  useEffect(() => {
    setCloudConnection({basePrefix: basePrefix});
  }, [basePrefix]);

  useEffect(() => {
    let containerUrl = getContainerUrlLocal();
    if (containerUrl !== cloudConnection.basePrefix && !isEmpty(cloudConnection.basePrefix)) {
      try {
        const url = new URL(cloudConnection.basePrefix);
        const hostParts = url.host.split('.');
        setAzureStorage({
          accountName: hostParts[0],
          containerName: url.pathname.replace(/^\/+/g, ''),
          endpoint: hostParts.slice(1, hostParts.length).join('.')
        });
      }  catch (error) {
        console.error('Container URL is malformed');
        setAzureStorage(initialAzureStorageState);
      }
    }
  }, [cloudConnection.basePrefix, getContainerUrlLocal, initialAzureStorageState]);

  useEffect(() => {
    if(onCloudConnectionChange){
      onCloudConnectionChange(cloudConnection);
    }
  }, [cloudConnection, onCloudConnectionChange]);

  const updateAzureStorage = (event) => {
    const tempAzureStorage = { ...azureStorage, [event.target.name]: event.target.value };
    if (!isEmpty(tempAzureStorage.accountName) || !isEmpty(tempAzureStorage.containerName)) {
      let containerUrl = getContainerUrl(tempAzureStorage.accountName, tempAzureStorage.endpoint, tempAzureStorage.containerName);
      if (containerUrl !== cloudConnection.basePrefix) {
        setCloudConnection((prevState) => ({...prevState, basePrefix: containerUrl}))
      }
    } else {
      setCloudConnection((prevState) => ({...prevState, basePrefix: ''}))
      setAzureStorage({ ...azureStorage, [event.target.name]: event.target.value });
    }
  };

  const updateBasePrefix = (event) => {
    setCloudConnection((prevState) => ({...prevState, basePrefix:  event.target.value}))
  }

  return (
    <>
      <FormControlInput name='accountName'
                        value={azureStorage.accountName}
                        width='100'
                        label='Storage Account Name'
                        autoComplete='off'
                        onChange={updateAzureStorage}
                        errorMessage={props.errors?.accountName ?? props.errors?.basePrefix}
      />
      <FormControlInput name='containerName'
                        value={azureStorage.containerName}
                        width='100'
                        label='Container Name'
                        autoComplete='off'
                        onChange={updateAzureStorage}
                        errorMessage={props.errors?.containerName ?? props.errors?.basePrefix}
      />
      <AdvancedSectionExpansion mt={3}>
        <FormControlInput value={cloudConnection.basePrefix} width='100' label='Container Resource URL'
                          name='basePrefix'
                          autoComplete="off"
                          onChange={updateBasePrefix}
                          placeholder='Enter URL for Azure Container'
                          helperText='Ex: https://storage-account-name.blob.core.windows.net/container-name'
                          errorMessage={props.errors?.basePrefix}
                          allowCopy
        />
      </AdvancedSectionExpansion>
    </>
  );
}

AzureCloudConnectionForm.propTypes = {
  basePrefix: PropTypes.string,
  errors: PropTypes.object,
  onCloudConnectionChange: PropTypes.func,
};

AzureCloudConnectionForm.defaultProps = {
  errors: {},
};

export default AzureCloudConnectionForm;
