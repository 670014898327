import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import { isEmpty } from '../../util/helpers';
import api_routes from '../../util/api_routes';
import {AWS, AZURE, GCP} from '../../util/cloud_providers';
import FormControlSelect from '../common/FormControlSelect';
import AwsCloudConnectionCredentialsForm from './AwsCloudConnectionCredentialsForm';
import GcpCloudConnectionCredentialsForm from './GcpCloudConnectionCredentialsForm';
import AzureCloudConnectionCredentialsForm from './AzureCloudConnectionCredentialsForm';
import CloudConnectionCredentialsListDropDown from './CloudConnectionCredentialsListDropDown';

const {cloudProvider: windowEnvCloudProvider} = window._env_ || {};
const CLOUD_PROVIDER = windowEnvCloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;

export const cloudCredentialsOptions = {
    INSTANCE: 'instance',
    NEW: 'new',
    EXISTING: 'existing',
}

// noinspection FunctionNamingConventionJS
const CloudConnectionCredentialsComponent = forwardRef((props, _ref) => {

    const {cloudProvider, errors, setIsCredentialBlocking} = props;
    const [credentialsOption, setCredentialsOption] = useState(cloudCredentialsOptions.INSTANCE);
    const [cloudCredentialsId, setCloudCredentialsId] = useState('');
    const [credentialsList, setCredentialsList] = useState([]);
    const [credentials, setCredentials] = useState({
        accessKeyId: '',
        accessSecret: '',
        jwtToken: '',
        jsonKeyFilename: '',
        connectionString: '',
        displayName: '',
    });

    const handleCredentialsChange = (data) => {
        setCredentials(prevState => ({...prevState, ...data}));
    }

    const handleCloudCredentialsSelectChange = (value) => {
        setCredentialsOption(value);
    }

    useEffect(() => {
        const hasCredentialChanged = cloudCredentialsId !== (props.cloudCredentialsId ?? '') || credentialsOption === cloudCredentialsOptions.NEW;
        setIsCredentialBlocking(hasCredentialChanged);
    },[cloudCredentialsId, props.cloudCredentialsId, credentialsOption, setIsCredentialBlocking, cloudProvider])

    useEffect(() => {
        if (credentialsOption === cloudCredentialsOptions.EXISTING && credentialsList.length === 0) {
            setCloudCredentialsId(-1);
        } else if (credentialsOption === cloudCredentialsOptions.EXISTING && credentialsList.length > 0 && isEmpty(cloudCredentialsId)) {
            setCloudCredentialsId(credentialsList[0].id);
        } else if (credentialsOption !== cloudCredentialsOptions.EXISTING) {
            setCloudCredentialsId('');
        }
    }, [credentialsOption, credentialsList, cloudCredentialsId]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(api_routes.cloudCredentials.endpoint,
                    {params: {cloudProvider}});
                return response.data
            } catch (e) {
                throw new Error(`Error fetching cloud credentials list due to: ${e.message}`)
            }
        }

        fetchData().then(list => {
            if (list.length > 0 && !isEmpty(props.cloudCredentialsId) && list.find(cred => (cred.id === props.cloudCredentialsId))) {
                setCloudCredentialsId(props.cloudCredentialsId);
                setCredentialsOption(cloudCredentialsOptions.EXISTING || props.cloudCredentialsInherited);
            } else if (cloudProvider === CLOUD_PROVIDER || props.cloudCredentialsInherited) {
                setCredentialsOption(cloudCredentialsOptions.INSTANCE);
            } else if (list.length > 0) {
                setCloudCredentialsId(list[0].id);
                setCredentialsOption(cloudCredentialsOptions.EXISTING);
            } else {
                setCredentialsOption(cloudCredentialsOptions.NEW);
            }
            setCredentialsList(list);
        });
    }, [cloudProvider, setCredentialsList, props.cloudCredentialsId, props.cloudCredentialsInherited]);

    useImperativeHandle(_ref, () => ({
        getCredentials: () => ({...credentials, cloudProvider, cloudCredentialsId, credentialsOption}),
        save: async () => {
            let payload = {...credentials, displayName: credentials.displayName.trim()};
            const newCloudCredentials = await axios.post(api_routes.cloudCredentials.endpoint, {
                ...payload,
                cloudProvider
            });
            setCloudCredentialsId(newCloudCredentials.data.id);
            return {...credentials, cloudProvider, cloudCredentialsId: newCloudCredentials.data.id, credentialsOption};
        },
    }));

    return (<>
        <Box mt={2} mb={1}>
            <FormControlSelect name='type'
                               type='select'
                               label={'Cloud Credentials'}
                               value={credentialsOption}
                               onChange={(event) => handleCloudCredentialsSelectChange(event.target.value)}
                               margin={'none'}
                               width={'100'}>
                {cloudProvider === AWS &&
                    <MenuItem value={cloudCredentialsOptions.INSTANCE}>Use Instance Profile</MenuItem>
                }
                {cloudProvider === GCP &&
                    <MenuItem value={cloudCredentialsOptions.INSTANCE}>Use Attached Service Account</MenuItem>
                }
                {cloudProvider === AZURE &&
                    <MenuItem value={cloudCredentialsOptions.INSTANCE}>Use Managed Identity</MenuItem>
                }
                <MenuItem value={cloudCredentialsOptions.NEW}>Create New Credentials</MenuItem>
                <MenuItem value={cloudCredentialsOptions.EXISTING}>Use Existing Credentials</MenuItem>
            </FormControlSelect>
        </Box>

        {(credentialsOption === cloudCredentialsOptions.NEW) && <>
            {(cloudProvider === AWS) &&
                <AwsCloudConnectionCredentialsForm credentials={credentials}
                                                   errors={errors}
                                                   handleChange={handleCredentialsChange}/>
            }
            {(cloudProvider === AZURE) &&
                <AzureCloudConnectionCredentialsForm credentials={credentials}
                                                     errors={errors}
                                                     handleChange={handleCredentialsChange}/>
            }
            {(cloudProvider === GCP) &&
                <GcpCloudConnectionCredentialsForm credentials={credentials}
                                                   errors={errors}
                                                   handleChange={handleCredentialsChange}/>
            }
        </>}

        {(credentialsOption === cloudCredentialsOptions.EXISTING) &&
            <CloudConnectionCredentialsListDropDown cloudProvider={cloudProvider}
                                                    credentials={credentialsList}
                                                    cloudCredentialsId={cloudCredentialsId}
                                                    setCloudCredentialsId={setCloudCredentialsId}/>
        }

    </>)
});

CloudConnectionCredentialsComponent.propTypes = {
    cloudProvider: PropTypes.string,
    errors: PropTypes.object,
    cloudCredentialsId: PropTypes.any,
    cloudCredentialsInherited: PropTypes.bool,
    setIsCredentialBlocking: PropTypes.func,
};

CloudConnectionCredentialsComponent.defaultProps = {
    cloudProvider: AWS,
};

export default CloudConnectionCredentialsComponent;
