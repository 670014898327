import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {ContentCopyRounded} from '@mui/icons-material';
import {Box, IconButton, List, ListItem, ListItemText, MenuItem} from '@mui/material';
import {NoOverflowTypography} from '../common/styled';
import FormControlSelect from '../common/FormControlSelect';
import AwsCloudConnectionForm from './provider/AwsCloudConnectionForm';
import AzureCloudConnectionForm from './provider/AzureCloudConnectionForm';
import GoogleCloudConnectionForm from './provider/GoogleCloudConnectionForm';
import useCloudConnection from '../../api/useCloudConnection';
import {cloudCredentialsOptions} from '../cloudconnectioncredentials/CloudConnectionCredentialsComponent';
import api_routes from '../../util/api_routes';
import copyTextToClipboard from '../../util/CopyText';
import {isEmpty, isEmptyObject} from '../../util/helpers';
import {AWS, AZURE, GCP, INHERITED} from '../../util/cloud_providers';
import {CustomWidthTooltip} from '../filesystem/FilesystemTableComponent';

const StyledListItem = styled(ListItem)`
  padding-left: 0.875em;
`;

const CloudConnectionComponent = forwardRef((props, _ref) => {

    const {cloudConnectionId, setCloudCredentialsId, setCloudCredentialsInherited, setIsConnectionBlocking} = props;
    let {connectionOption} = props;

    const theme = useTheme();

    const location = useLocation();
    const provider = new URLSearchParams(location.search).get("provider");

    const {errors} = props;

    const {cloudConnection: cloudConnectionFetched, isLoading} = useCloudConnection({
        params: {
            cloudConnectionId,
            updateConnectivity: false
        }
    });
    const [cloudConnection, setCloudConnection] = useState({});

    useEffect(() => {
        let hasConnectionChanged = true;
        if (connectionOption === INHERITED && (isEmptyObject(cloudConnectionFetched) ||
            props.inheritedCloudConnectionSourceFolderPath === cloudConnectionFetched.folderPath)) {
            hasConnectionChanged = false;
        } else if (connectionOption === cloudConnectionFetched.cloudProvider) {
            hasConnectionChanged = ["basePrefix", "region", "encryptionId", "encryptionType"].some((key) => {
                return (cloudConnection[key] ?? '') !== (cloudConnectionFetched[key] ?? '');
            });
        }
        setIsConnectionBlocking(hasConnectionChanged)
    }, [cloudConnection, cloudConnectionFetched, connectionOption, setIsConnectionBlocking, props.inheritedCloudConnectionSourceFolderPath])

    useEffect(() => {
        setCloudCredentialsId(cloudConnectionFetched.cloudCredentialId);
        setCloudCredentialsInherited(cloudConnectionFetched.useInstanceCredentials);
    }, [cloudConnectionFetched.cloudCredentialId, cloudConnectionFetched.useInstanceCredentials,
        setCloudCredentialsId, setCloudCredentialsInherited]);

    useImperativeHandle(_ref, () => ({
        getCloudConnection: () => ({
            cloudConnection,
            connectionOption,
            originalCloudConnection: cloudConnectionFetched
        }),
        save: async (folderPath, cloudCredentialId, credentialOption) => {
            let newCloudConnection = {};
            if (connectionOption === INHERITED) {
                await axios.delete(api_routes.cloudConnection.endpoint, {params: {folderPath}})
            } else {
                const data = {
                    ...cloudConnection,
                    cloudProvider: connectionOption,
                    useInstanceCredentials: !cloudCredentialId,
                    folderPath
                }
                if (credentialOption) {
                    data.useInstanceCredentials = credentialOption === cloudCredentialsOptions.INSTANCE;
                }
                if (cloudCredentialId && !data.useInstanceCredentials) {
                    data.cloudCredentialId = cloudCredentialId;
                }

                newCloudConnection = await axios.post(api_routes.cloudConnection.endpoint, data);
                setIsConnectionBlocking(false);
            }
            return {...newCloudConnection.data, connectionOption}
        },
    }));

    const {onCloudProviderChange} = props;

    useEffect(() => {
        if ((!connectionOption || connectionOption === INHERITED) && props.disableInheritedOption) {
            //Root folder cannot be set to inherited. Default it to passed-in provider, or AWS
            let connectionOption = provider ?? props.inheritedCloudProvider ?? AWS;
            onCloudProviderChange(connectionOption)
        }
    }, [connectionOption, provider, props.disableInheritedOption, props.inheritedCloudProvider, onCloudProviderChange])

    return (<>
        {isLoading && <>Loading Cloud Connection...</>}
        {!isLoading && <><Box mt={2} mb={1}>
            <FormControlSelect name='type'
                               type='select'
                               label={'Cloud Connection'}
                               value={connectionOption}
                               onChange={(event) => onCloudProviderChange(event.target.value)}
                               margin={'none'}
                               width={'100'}
                               errorMessage={errors?.type}
                               error={!isEmpty(errors?.type)}
            >
                <MenuItem disabled={props.disableInheritedOption} value={INHERITED}>Inherit from Ancestor
                    Folders</MenuItem>
                <MenuItem value={AWS}>Map to AWS S3</MenuItem>
                <MenuItem value={AZURE}>Map to Azure Blob Storage</MenuItem>
                <MenuItem value={GCP}>Map to Google Cloud Bucket</MenuItem>
            </FormControlSelect>
        </Box>
            {connectionOption === INHERITED && <>
                <List sx={{fontSize: '0.875rem'}}>
                    <StyledListItem>
                        <ListItemText primary='Inherited Cloud Connection From Path'
                                      secondary={<Box component='span' display='flex' alignItems='center'
                                                      sx={{color: theme.palette.text.primary}}>
                                          {props.inheritedCloudConnectionSourceFolderPath}
                                      </Box>}
                        />
                    </StyledListItem>

                    <StyledListItem>
                        <ListItemText primary='Cloud Provider'
                                      secondary={<Box component='span' display='flex' alignItems='center'
                                                      sx={{color: theme.palette.text.primary}}>
                                          {props.inheritedCloudProvider}
                                      </Box>}
                        />
                    </StyledListItem>

                    <StyledListItem>
                        <ListItemText primary='Resolved Cloud Connection'
                                      secondary={
                                          <CustomWidthTooltip title={props.inheritedCloudAbsolutePath}
                                                              sx={{maxWidth: '500'}}>
                                              <Box component='div' display='flex' alignItems='center'
                                                   sx={{color: theme.palette.text.primary}}>
                                                  <NoOverflowTypography variant={"body2"}>
                                                      {props.inheritedCloudAbsolutePath}
                                                  </NoOverflowTypography>
                                                  <IconButton size='small'
                                                              onClick={event => copyTextToClipboard(event, props.inheritedCloudAbsolutePath)}>
                                                      <ContentCopyRounded fontSize='small' color='primary'/>
                                                  </IconButton>
                                              </Box>
                                          </CustomWidthTooltip>}
                        />
                    </StyledListItem>
                </List>
            </>}
            {connectionOption === AWS &&
                <AwsCloudConnectionForm
                    onCloudConnectionChange={setCloudConnection}
                    basePrefix={cloudConnectionFetched.cloudProvider === AWS ? cloudConnectionFetched.basePrefix : ''}
                    encryptionType={cloudConnectionFetched.encryptionType}
                    encryptionId={cloudConnectionFetched.encryptionId}
                    region={cloudConnectionFetched.region}
                    errors={errors}>
                </AwsCloudConnectionForm>
            }
            {connectionOption === AZURE &&
                <AzureCloudConnectionForm
                    onCloudConnectionChange={setCloudConnection}
                    basePrefix={cloudConnectionFetched.cloudProvider === AZURE ? cloudConnectionFetched.basePrefix : ''}
                    errors={errors}>
                </AzureCloudConnectionForm>
            }
            {connectionOption === GCP &&
                <GoogleCloudConnectionForm
                    onCloudConnectionChange={setCloudConnection}
                    basePrefix={cloudConnectionFetched.cloudProvider === GCP ? cloudConnectionFetched.basePrefix : ''}
                    errors={errors}></GoogleCloudConnectionForm>
            }
        </>}
    </>)

});

CloudConnectionComponent.propTypes = {
    cloudProvider: PropTypes.string,
    onCloudProviderChange: PropTypes.func,
    inheritedCloudAbsolutePath: PropTypes.string,
    inheritedCloudProvider: PropTypes.string,
    inheritedCloudConnectionSourceFolderPath: PropTypes.string,
    disableInheritedOption: PropTypes.bool,
    errors: PropTypes.object,
    setIsConnectionBlocking: PropTypes.func,
};

CloudConnectionComponent.defaultProps = {
    cloudProvider: AWS,
    onCloudProviderChange: () => {
    }
};

export default CloudConnectionComponent;
