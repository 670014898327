import axios from 'axios';

function upload(endpoint, file, path, onUploadProgress) {

    let formData = new FormData();

    formData.append('fileLastModifiedTime', file.lastModified);
    formData.append('file', file, path);

    return axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
    }).then((result) => ({...result, path}))
        .catch((error) => {
            throw {...error, path}
        })
}

function download(endpoint, onDownloadProgress) {

    return axios.get(endpoint,
        {
            responseType: 'blob',
            onDownloadProgress
        })
}

export const fileService = {
    upload,
    download
}
