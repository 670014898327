import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import CircularProgressButton from './common/CircularProgressButton';
import {isEmptyObject} from '../util/helpers';
import routes from '../util/routes';

// noinspection FunctionNamingConventionJS
function SystemInfoComponent() {
    const history = useHistory();
    const [openSystemInfoDialog, setOpenSystemInfoDialog] = useState(false);
    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    useEffect(() => {
        // noinspection ChainedFunctionCallJS
        let systemHasErrors = Object.keys(systemInfo).some(key => !systemInfo[key]);
        setOpenSystemInfoDialog(systemHasErrors);

        if (!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet) {
            // Automatically redirect to the Update root folder Form
            history.push(
                `${routes.filesystemfuncUpdateFolder.path("")}&provider=${systemInfo.cloudProvider}&redirect=${routes.home.path}`);
        }
    }, [systemInfo, history]);

    const handleCloudConnection = function handleCloudConnectionSystemError() {
        setOpenSystemInfoDialog(false);
    };

    return (
        <>
            {!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet &&
            <Dialog open={openSystemInfoDialog} onClose={() => setOpenSystemInfoDialog(false)}>

                <DialogTitle>Configure Default Storage</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        StorageLink requires a Cloud Connection for users to store and retrieve files.
                        Use the underlying form to map a cloud connection to the root folder.
                    </DialogContentText>
                    <CircularProgressButton onClick={handleCloudConnection} buttonTextTransform='none'
                                            label='OK'/>
                </DialogContent>

            </Dialog>
            }
        </>
    );
}

SystemInfoComponent.propTypes = {};

SystemInfoComponent.defaultProps = {};

export default SystemInfoComponent;
