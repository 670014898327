import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import PasswordField from '../common/PasswordField';
import FormControlSelect from '../common/FormControlSelect';
import CircularProgressButton from '../common/CircularProgressButton';
import FormControlInput, { StyledTypographyLabel } from '../common/FormControlInput';
import ConfirmDialog from '../common/ConfirmDialog';
import { Role, RoleDisplayDict } from '../auth/accessControl/role';
import { isEmpty, isUndefined, stripStartingAndTrailingSlashes } from '../../util/helpers';
import FolderForm from '../filesystem/FolderForm';
import useFilesystem from '../../api/useFilesystem';
import { useHistory } from "react-router-dom";
import PageLoader from "../common/PageLoader";
import httpStatus from '../../util/http_status';
import { Prompt } from "react-router";
import _ from "lodash";

function UserForm(props) {

    const history = useHistory();
    const theme = useTheme();
    const [isBlocking, setIsBlocking] = useState(false);
    const [isUserBlocking, setIsUserBlocking] = useState(false);
    const [isFolderFormBlocking, setIsFolderFormBlocking] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const {user, initialUser, homeFolderManualChange} = props;
    const folderFormRef = useRef();
    const [homeFolderPath, setHomeFolderPath] = useState(user.homeFolderPath);
    const [folderErrors, setFolderErrors] = useState({});

    const {data: folders, isLoading: isFolderDataLoading} = useFilesystem({
        params: {
            path: homeFolderPath,
            metadataOnly: true
        }
    });

    useEffect(() => {
        if (!isEmpty(user.homeFolderPath)) {
            setHomeFolderPath(user.homeFolderPath);
        }
    }, [user.homeFolderPath]);

    useEffect(() => {
        setIsBlocking(isUserBlocking || isFolderFormBlocking);
    }, [isUserBlocking, isFolderFormBlocking, submitting]);

    useEffect(() => {
        const hasUserChanged = !_.isEqual({...user,
            homeFolderPath: homeFolderManualChange ? user.homeFolderPath : initialUser.homeFolderPath}, initialUser);
        setIsUserBlocking(hasUserChanged);
    }, [homeFolderManualChange, user, initialUser])

    const handleSubmitFolder = async function updateFilesystemFolder() {
        const array = stripStartingAndTrailingSlashes(homeFolderPath).split("/");
        const folderName = array.pop();

        try {
            await props.saveFolder({folderName, cloudConnectionId: null, homeFolderPath});
        } catch (error) {
            // Let through "Folder already exists" error, throw the rest.
            if (error && error.response && error.response.status === httpStatus.badRequest
                && !error.response.data.message.includes("already exists")) {
                throw error;
            }
        }
    }

    const handleSubmit = async function handleUserSubmitEvent(event) {
        event.preventDefault();
        setSubmitting(true);
        try {
            await props.handleSubmit(event);
            setIsUserBlocking(false);
            if (folderFormRef.current) {
                await folderFormRef.current.handleSubmit(event);
                setIsFolderFormBlocking(false);
            } else {
                history.goBack();
            }
        } catch (error){
            console.error(error);
            setSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControlInput value={user.username} width='100' label='Username'
                              name='username' autoFocus autoComplete='off'
                              placeholder='Enter unique username' onChange={props.handleChange}
                              errorMessage={props.errors.username}
            />
            <Box mt={2} mb={1}>
                <FormControlSelect name='role' type='select' defaultValue={Role.USER} width={'100'}
                                   label={'Role'} value={user.role}
                                   onChange={props.handleChange} margin={'none'}
                >
                    <MenuItem value={Role.USER}>{RoleDisplayDict[Role.USER]}</MenuItem>
                    <MenuItem value={Role.ADMIN}>{RoleDisplayDict[Role.ADMIN]}</MenuItem>
                </FormControlSelect>
            </Box>
            <FormControlInput value={user.notes} width='100' label='Notes' name='notes'
                              multiline onChange={props.handleChange} inputProps={{maxLength: 1000}}
                              showCount autoComplete={'off'}
                              placeholder='Enter notes about the user (optional)' onChangeReturnEvent
                              errorMessage={props.errors.notes}
            />
            <PasswordField password={user.password} onChange={props.handleChange}
                           placeholder={user.usesPassword ? 'Password is set' : 'Enter Password'}
                           generatePassword
                           visibilityToggle={!isEmpty(user.password)}
                           allowCopy={!isEmpty(user.password)}
                           width='100' errorMessage={props.errors.password}/>

            <FormControlLabel label='Change password at sign in' labelPlacement='end'
                              control={
                                  <Checkbox checked={!isEmpty(user.password) && user.resetPassword}
                                            indeterminate={isEmpty(user.password)} color='primary'
                                            onChange={(event) => props.handleCheckboxChange('resetPassword', event)}/>}
                              disabled={isEmpty(user.password)}
            />

            {user.role === Role.USER &&
                <>
                    <FormControlInput value={user.homeFolderPath} width='100' label='Home Folder' name='homeFolderPath'
                                      onChange={props.handleChange} autoComplete={'off'}
                                      helperText="The user's access will be restricted to this home directory and its subdirectories."
                                      placeholder='Provide username to generate default  folder path'
                                      onChangeReturnEvent
                    />

                    {isFolderDataLoading && <PageLoader/>}
                    {!isFolderDataLoading &&
                      <>
                          <Box mt={2} mb={1}>
                              <FormControl component="fieldset" variant="standard">
                                  <StyledTypographyLabel variant={"body1"}>
                                      Home Folder Permissions
                                  </StyledTypographyLabel>
                                  <FormGroup>
                                      <FormControlLabel label="List files"
                                                        control={<Checkbox checked={user.homeFolderPermissions.listable}
                                                                           onChange={(event) => props.handleFolderPermissionChange('listable', event)}/>}
                                      />
                                      <FormControlLabel label="Upload"
                                                        control={<Checkbox checked={user.homeFolderPermissions.uploadable}
                                                                           onChange={(event) => props.handleFolderPermissionChange('uploadable', event)}/>}
                                      />
                                      <FormControlLabel label="Download"
                                                        control={<Checkbox checked={user.homeFolderPermissions.downloadable}
                                                                           onChange={(event) => props.handleFolderPermissionChange('downloadable', event)}/>}
                                      />
                                      <FormControlLabel label="Delete/Overwrite"
                                                        control={<Checkbox checked={user.homeFolderPermissions.deletable}
                                                                           onChange={(event) => props.handleFolderPermissionChange('deletable', event)}/>}
                                      />
                                  </FormGroup>
                              </FormControl>
                          </Box>
                            <FolderForm ref={folderFormRef}
                                        folder={folders[0]}
                                        showUserPermissions={false}
                                        path={homeFolderPath}
                                        saveFolder={handleSubmitFolder}
                                        errors={folderErrors}
                                        setErrors={setFolderErrors}
                                        setIsFolderFormBlocking={setIsFolderFormBlocking}
                            />
                      </>
                    }
                </>
            }
            <Box display='flex' justifyContent='space-between'>
                <Box display='flex'>
                    <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                            label='Save' mt={2} inProgress={submitting} fullWidth={false}/>
                    <CircularProgressButton label='Cancel' theme={theme} type='reset' size='small' mt={2}
                                            onClick={props.handleCancel} variant='text'/>
                    <Prompt when={!submitting && isBlocking}
                            message={'Are you sure you would like to cancel? Your changes will not be saved.'}/>
                </Box>
                {!isUndefined(user.id) &&
                    <Box>
                        <CircularProgressButton label='Delete' theme={theme} type='button' size='small' mt={2}
                                                onClick={() => {setConfirmDeletion(true);}}
                                                    style={{backgroundColor: red['800']}}/>
                        <ConfirmDialog
                            title='Delete User'
                            open={confirmDeletion}
                            setOpen={setConfirmDeletion}
                            onConfirm={() => {
                                setSubmitting(true);
                                props.handleDelete();
                            }}
                        >
                            Are you sure you want to delete {user.username}?
                        </ConfirmDialog>
                    </Box>
                }
            </Box>
        </form>
    );
}

UserForm.propTypes = {
    user: PropTypes.object,
    initialUser: PropTypes.object,
    handleChange: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    handleFolderPermissionChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    handleDelete: PropTypes.func,
    errors: PropTypes.object,
    saveFolder: PropTypes.func,
    homeFolderManualChange: PropTypes.bool,
};

UserForm.defaultProps = {};

export default UserForm;
